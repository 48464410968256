import React, { useState } from "react";
import classes from "./Projet.module.css";
// Components
import Articles from "./Articles/Articles";
import UploadDocument from "./UploadDocument/UploadDocument";

const Projet = ({ width, claims, clicked }) => {
  const [pdfs, setPdfs] = useState();
  return (
    <div className={classes.Projet}>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionSvg}>
          <img
            style={{ width: "100%" }}
            alt="hand"
            src={require(`../../../assets/homePage/concertation.svg`)}
          />
        </div>
        <div className={classes.BackgroundShape}></div>
        <div className={classes.Description}>
          <p>
            Le site fait l'objet d’un programme de restructuration urbaine.
            L’objectif étant de désenclaver le quartier, transformer le secteur
            pour créer des effets de masse et de changer l’image. Le projet
            prévoit la réhabilitation et résidentialisation de 21 bâtiments soit
            1062 logements, la démolition complète ou partielle de 10 bâtiments
            soit 735 logements.
          </p>
          <p>
            Les deux îlots concernés par la « Phase 1 » de résidentialisation
            menée par Immobilière 3F sont les îlots « Manet » et « Courbet ».
            Ils sont implantés au cœur du quartier, entouré par de nombreuses
            opérations de démolition-reconstruction qui transformeront
            profondément le quartier. L’îlot Courbet est également en lisière du
            Parc du Fort de Stains au sud-est.
          </p>
          <p>
            Grâce à cette plateforme, vous pourrez vous tenir informé de tous
            les travaux en bas de chez vous ainsi que proposer vos idées aux
            concepteurs.{" "}
          </p>
          <div className={classes.ActionButton}>
            <p onClick={clicked}>Accéder aux travaux {">"}</p>
            {/* <div className={classes.ActionButtonSvg}>
              <img
                style={{ width: "100%" }}
                alt="hand"
                src={require(`../../../assets/homePage/main.svg`)}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className={classes.Articles}>
        <div className={classes.ArticlesHeader}>
          <h4 style={{ marginBottom: "1.5rem" }}>Actualités</h4>
          {claims && claims.admin && (
            <UploadDocument setPdfs={setPdfs} claims={claims} />
          )}
        </div>
        <Articles setPdfs={setPdfs} pdfs={pdfs} claims={claims} />
      </div>
    </div>
  );
};

export default Projet;
