import React, { useState } from "react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import confettiCannons from "../../assets/lottie/confetti-cannons.json";
import styled from "styled-components";
import Title from "../elements/CardTitle";
import NextButton from "../elements/NextButton";
import Pin from "../elements/ActionPin";
import Wrapper from "../elements/CardWrapper";
import Description from "../elements/CardDescription";

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const ImageWrapper = styled(motion.div)`
  margin: 0 auto;
  max-width: 40rem;
  position: relative;
`;

const ArrowTop = styled.span`
  position: absolute;
  width: 0px;
  height: 0px;
  border-right: 120px solid transparent;
  border-bottom: 30px solid #f9f9f9;
  border-left: 120px solid transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
`;
const CloseButton = styled(motion.p)`
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-top: 1rem;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

const Progress = styled(motion.p)`
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: #ffffff;
  text-align: center;
`;
const LottieWrapper = styled(motion.div)`
  position: absolute;
  max-width: 20rem;
  bottom: 7%;
  left: 50%;
  transform: translateX(-50%);

  pointer-events: none;
`;

const PinsType = ({
  dispatch,
  contentData,
  cardID,
  cardIndex,
  contentIndex,
  contentLength,
  cardsLength,
}) => {
  const { imageUrl, title, openedPinsCount, pins } = contentData;

  const [showDescription, setShowDescription] = useState(false);
  const [selectedPin, setSelectedPin] = useState(null);

  const pinClickHandler = (pinIndex) => {
    dispatch({
      type: "SET_OPENEDPIN",
      cardID: cardID,
      cardIndex: cardIndex,
      contentIndex: contentIndex,
      pinIndex: pinIndex,
    });
    dispatch({
      type: "SET_OPENEDPINSCOUNT",
      cardID: cardID,
      cardIndex: cardIndex,
      contentIndex: contentIndex,
      pinIndex: pinIndex,
    });
    setSelectedPin(pinIndex);
    setShowDescription(true);
  };

  return (
    <Wrapper variant="cardMain">
      <Title
        layoutId="title"
        transition={{ duration: 0.5 }}
        color="#fff"
        style={{ paddingLeft: "2rem" }}
      >
        {title}
      </Title>

      <ImageWrapper
        layoutId="imageIllustration"
        drag="x"
        dragConstraints={{ left: -0, right: 0 }}
      >
        <img
          alt="La résidentialisation"
          src={require(`../../assets/travaux/${imageUrl}`)}
          style={{ width: "100%" }}
        />
        {pins.map((pin, index) => (
          <Pin
            layoutId={pin.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
            key={pin.pinId}
            top={pin.top}
            left={pin.left}
            onClick={() => pinClickHandler(index)}
            opened={pin.opened}
            open={index === selectedPin}
          ></Pin>
        ))}
      </ImageWrapper>

      {showDescription ? (
        <>
          <Description
            variant="cardMain"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ArrowTop />
            {pins[selectedPin].description}
          </Description>
          <CloseButton
            onClick={() => {
              setShowDescription(false);
              setSelectedPin(null);
            }}
          >
            Fermer
          </CloseButton>
        </>
      ) : openedPinsCount < pins.length ? (
        <Progress
          layoutId="progress"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {openedPinsCount}/{pins.length} informations explorées
        </Progress>
      ) : (
        <>
          <LottieWrapper>
            <Lottie animationData={confettiCannons} loop={false} />
          </LottieWrapper>

          <ButtonWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <NextButton
              href={
                parseInt(contentIndex) < contentLength - 1
                  ? `/travaux/${cardIndex}/${parseInt(contentIndex) + 1}`
                  : "/travaux"
              }
            >
              Valider
            </NextButton>
          </ButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default PinsType;
