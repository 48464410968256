import { db } from "../config/firebase";
import { codesLoc } from "../utils/LoginUtils";

// Load results from firebase
export const loadResults = ({ dispatch }) => {
  db.collection("locataires")
    .get()
    .then((snapshot) => {
      dispatch({ type: "SET_PARTICIPANTS", value: snapshot.size });
      // let csv = "Nom,Mobile,Réference locataire,Date\n";
      // snapshot.forEach((doc) => {
      //   csv += `${doc.data().fullName},${doc.data().numTel},${
      //     doc.data().refLoc
      //   },${doc.data().dateCreation}`;
      //   csv += "\n";
      // });
      // console.log(csv);
      console.log(snapshot.size);
    })
    .catch((err) => console.log(err));

  // db.collection("resultats")
  //   .get()
  //   .then((snapshot) => {
  //     dispatch({ type: "SET_REPONSES", value: snapshot.size });
  //   })
  //   .catch((err) => console.log(err));
};

export const loadParticipants = () => {
  let results = {};
  codesLoc.map(
    (code) => (results[code] = { locataire: code, participated: false })
  );

  db.collection("locataires")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        if (codesLoc.includes(doc.data().refLoc)) {
          results[doc.data().refLoc].participated = true;
        }
      });
    })
    .then(() => {
      let csv = "Réference locataire,Participated\n";

      Object.keys(results).map((key, index) => {
        csv += `${results[key].locataire},${results[key].participated}`;
        csv += "\n";
      });
      console.log(csv);
    })
    .catch((err) => console.log(err));
};
