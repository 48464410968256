import React from "react";

export const travauxCardsData = [
  {
    id: "i2a",
    type: "newLayout",
    titleStrong: "La résidentialisation",
    titleWeak: "",
    description:
      "La résidentialisation des immeubles Rue Auguste Renoir (U3), rue Claude Monet (U4) et rue Jean Fragonard (U2)  consiste à redonner un caractère privé à votre immeuble. Notez que dans le cadre du projet de restructuration du quartier, il a été décidé de laisser à la Ville le square et les parkings. Le foncier de 3F se limite au pourtour de bâtiments.",
    thumbnail: "u2-u3-u4.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "La résidentialisation des immeubles Rue Auguste Renoir (U3), rue Claude Monet (U4) et rue Jean Fragonard (U2)  consiste à redonner un caractère privé à votre immeuble. Notez que dans le cadre du projet de restructuration du quartier, il a été décidé de laisser à la Ville le square et les parkings. Le foncier de 3F se limite au pourtour de bâtiments.",
        title: "La résidentialisation",
        video: {
          url: "https://youtu.be/M7WNn9Ql4oM",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i2b",
    type: "newLayout",
    titleStrong: "La résidentialisation",
    titleWeak: "",
    description:
      "La résidentialisation de l’immeuble Allée Gustave Courbet (U6) et de la tour Rue Gustave Courbet (T7) consiste à redonner un caractère privé à votre immeuble. Notez que dans le cadre du projet de restructuration du quartier, il a été décidé de laisser à la Ville l’aire de jeu et le parking derrière l’immeuble allée Gustave Courbet  (U6). Le foncier de 3F se limite au pourtour de bâtiments et l’espace commun entre les bâtiments U6 et T7.",
    thumbnail: "u6-t7.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "La résidentialisation de l’immeuble Allée Gustave Courbet (U6) et de la tour Rue Gustave Courbet (T7) consiste à redonner un caractère privé à votre immeuble. Notez que dans le cadre du projet de restructuration du quartier, il a été décidé de laisser à la Ville l’aire de jeu et le parking derrière l’immeuble allée Gustave Courbet  (U6). Le foncier de 3F se limite au pourtour de bâtiments et l’espace commun entre les bâtiments U6 et T7. ",
        title: "La résidentialisation",
        video: {
          url: "https://youtu.be/xWYUa0v0CtE",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["b"],
  },
  {
    id: "i3a",
    type: "newLayout",
    titleStrong: "La délimitation",
    titleWeak: "de l’espace privé et public",
    description:
      "Pour plus de détails sur le traitement de l’espace privé et public des immeubles Rue Auguste Renoir (U3), rue Claude Monet (U4) et rue Jean Fragonard (U2) suivez le guide en vidéo.",
    thumbnail: "u2-u3-u4.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Pour plus de détails sur le traitement de l’espace privé et public des immeubles Rue Auguste Renoir (U3), rue Claude Monet (U4) et rue Jean Fragonard (U2) suivez le guide en vidéo.",
        title: "La délimitation de l’espace privé et public",
        video: {
          url: "https://youtu.be/C0XgRRCbO7E",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a"],
  },
  {
    id: "i3b",
    type: "newLayout",
    titleStrong: "La délimitation",
    titleWeak: "de l’espace privé et public",
    description:
      "Pour plus de détails sur le traitement de l’espace privé et public de l’immeuble Allée Gustave Courbet (U6) et de la tour Rue Gustave Courbet (T7) suivez le guide en vidéo.",
    thumbnail: "u6-t7.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Pour plus de détails sur le traitement de l’espace privé et public de l’immeuble Allée Gustave Courbet (U6) et de la tour Rue Gustave Courbet (T7) suivez le guide en vidéo.",
        title: "La délimitation de l’espace privé et public",
        video: {
          url: "https://youtu.be/cx-y4kRv91I",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["b"],
  },

  {
    id: "i4a",
    type: "newLayout",
    titleStrong: "Le traitement",
    titleWeak: "des halls d’immeuble",
    description:
      "Le traitement des halls d’immeubles consiste à améliorer l’accessibilité des halls pour tous les usagers de l’immeuble et réorganiser les entrées en les orientant côté rue pour préserver la tranquillité en cœur d'îlot.",
    thumbnail: "Hall-escalier.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "Le traitement des halls d’immeubles consiste à améliorer l’accessibilité des halls pour tous les usagers de l’immeuble et réorganiser les entrées en les orientant côté rue pour préserver la tranquillité en cœur d'îlot.",
        title: "Le traitement des halls d’immeuble",
        value: false,
        imageBefore: "Hall-escalier.svg",
        imageAfter: "Hall-pente.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description:
              "Pour faciliter l’accès de tous à l’immeuble des rampes PMR sont installées.",
            top: "68%",
            left: "55%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description:
              "Le hall traversant est remplacé par un hall à entrée unique.",
            top: "52%",
            left: "44%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i4b",
    type: "newLayout",
    titleStrong: "Le traitement",
    titleWeak: "des halls d’immeuble",
    description:
      "Le traitement des halls d’immeubles consiste à améliorer l’accessibilité des halls pour tous les usagers de l’immeuble et réorganiser les entrées en les orientant côté rue pour préserver la tranquillité en cœur d'îlot.",
    thumbnail: "Hall-escalier.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "Le traitement des halls d’immeubles consiste à améliorer l’accessibilité des halls pour tous les usagers de l’immeuble et réorganiser les entrées en les orientant côté rue pour préserver la tranquillité en cœur d'îlot.",
        title: "Le traitement des halls d’immeuble",
        value: false,
        imageBefore: "Hall-escalier.svg",
        imageAfter: "Hall-pente.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description:
              "Pour faciliter l’accès de tous à l’immeuble des rampes PMR sont installées.",
            top: "68%",
            left: "55%",
            opened: false,
          },
          {
            pinId: "pin-2",
            description:
              "Le hall traversant est remplacé par un hall à entrée unique côté des rues principales. Cependant des accès directs sont conservés pour la Tour Rue Gustave Courbet.",
            top: "52%",
            left: "44%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["b"],
  },
  {
    id: "i5a",
    type: "newLayout",
    titleStrong: "Le traitement",
    titleWeak: "des ordures ménagères",
    description:
      "Le traitement des ordures ménagères est entièrement revu pour gagner en praticité, en esthétisme et réduire les nuisances.",
    thumbnail: "Poubelles-a-l-ancienne.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "Le traitement des ordures ménagères est entièrement revu pour gagner en praticité, en esthétisme et réduire les nuisances.",
        title: "Le traitement des ordures ménagères.",
        value: false,
        imageBefore: "Poubelles-a-l-ancienne.svg",
        imageAfter: "Poubelles-enterrées.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description:
              "Les poubelles sont enterrées à l’extérieur mais demeurent dans l’espace privé de votre immeuble.",
            top: "65%",
            left: "39%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["a"],
  },
  {
    id: "i5b",
    type: "newLayout",
    titleStrong: "Le traitement",
    titleWeak: "des ordures ménagères",
    description:
      "Le traitement des ordures ménagères est entièrement revu pour gagner en praticité, en esthétisme et réduire les nuisances.",
    thumbnail: "Poubelles-a-l-ancienne.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "beforeAfter",
        description:
          "Le traitement des ordures ménagères est entièrement revu pour gagner en praticité, en esthétisme et réduire les nuisances.",
        title: "Le traitement des ordures ménagères.",
        value: false,
        imageBefore: "Poubelles-a-l-ancienne.svg",
        imageAfter: "Poubelles-enterrées.svg",
        openedPinsCount: 0,
        pins: [
          {
            pinId: "pin-1",
            description:
              "Les poubelles sont pour cela enterrées à l’extérieur mais demeurent dans l’espace privé de votre immeuble. Aussi, un nouveau local pour les encombrants est créé.",
            top: "65%",
            left: "39%",
            opened: false,
          },
        ],
      },
    ],
    tags: "Avant / Aprés",
    groupe: ["b"],
  },
  {
    id: "i6",
    type: "newLayout",
    titleStrong: "La réfection",
    titleWeak: "des descentes d'eau",
    description:
      "Pour plus de détails sur la réfection des descentes d’eau des immeubles Rue Auguste Renoir (U3), rue Claude Monet (U4) et rue Jean Fragonard (U2), Allée Gustave Courbet (U6) et de la tour Rue Gustave Courbet (T7) suivez le guide en vidéo.",
    thumbnail: "descentes-eau.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Pour plus de détails sur la réfection des descentes d’eau des immeubles Rue Auguste Renoir (U3), rue Claude Monet (U4) et rue Jean Fragonard (U2), Allée Gustave Courbet (U6) et de la tour Rue Gustave Courbet (T7) suivez le guide en vidéo.",
        title: "La réfection des descentes d'eau",
        video: {
          url: "https://youtu.be/baDntG9ZQsg",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b"],
  },
  {
    id: "c1",
    type: "newLayout",
    titleStrong: "Délimitation",
    titleWeak: "des espaces privés et publics ",
    description:
      "Pour délimiter les espaces privés de votre immeuble de l’espace public, les concepteurs choisissent de mettre en place une barrière végétale au pied de votre immeuble. Et pour cela, ils ont besoin de vous !",
    thumbnail: "autre-delim.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Délimitation des espaces privés et publics",
        question:
          "Quel type de végétalisation en pied d’immeuble verrez-vous pour délimiter le domaine privé de votre résidence ?",
        imageUrl: "autre-delim.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des fleurs.",
            value: "rep1",
            image: "fleurs.svg",
          },
          {
            label:
              "Des plantes persistantes qui gardent leurs feuilles même en hiver.",
            value: "rep2",
            image: "plantes-persistantes.svg",
          },
          {
            label: "Des arbres fruitiers.",
            value: "rep3",
            image: "arbres-fruitiers.svg",
          },
          {
            label: "Des jardins potagers.",
            value: "rep4",
            image: "jardin-potager.svg",
          },
          {
            label: "Une clôture avec des herbes hautes.",
            value: "rep5",
            image: "herbes-sauvages.svg",
          },
          {
            label: "J’ai une autre idée !",
            value: "rep100",
            image: "autre-delim.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b"],
  },
  {
    id: "c2",
    type: "newLayout",
    titleStrong: "Le mobilier dans les",
    titleWeak: "espaces privés extérieurs",
    description:
      "Le domaine nouvellement privatisé entre les immeubles possède des espaces résiduels que les concepteurs décident de transformer en jardin à l’usage des résidents. Pour aménager ce jardin, ils ont besoin de vous !",
    thumbnail: "Autre.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Le mobilier dans les espaces privés extérieurs",
        question:
          "Quel type de mobilier verrez-vous pour agrémenter ces jardins ?",
        imageUrl: "Autre.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Détente: Chaise et banc",
            value: "rep1",
            image: "Détente.svg",
          },
          {
            label: "Rencontre: Table de picnic",
            value: "rep2",
            image: "Rencontre.svg",
          },
          {
            label: "Biodiversité: Abris oiseaux, hôtel à insectes…",
            value: "rep3",
            image: "Biodiversité.svg",
          },
          {
            label: "Amusement: Petit jeu pour enfants.",
            value: "rep4",
            image: "Amusement.svg",
          },
          {
            label: "Sport: Petits agrès de musculation en bois",
            value: "rep5",
            image: "Sport.svg",
          },
          {
            label: "Art: Sculptures",
            value: "rep6",
            image: "Art.svg",
          },
          {
            label: "Pelouse libre",
            value: "rep7",
            image: "Pelouse-libre.svg",
          },
          {
            label: "J’ai une autre idée !",
            value: "rep100",
            image: "Autre.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b"],
  },
  {
    id: "c3",
    type: "newLayout",
    titleStrong: "Création",
    titleWeak: "de jardins partagés",
    description:
      "Entre la Tour Rue Courbet et l’Immeuble Allée Gustave Courbet, les concepteurs veulent potentiellement mettre en place un jardin partagé dont la gestion sera donnée à une structure locale. En avez-vous envie et si oui quelles seraient vos idées.",
    thumbnail: "Oui.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Création de jardins partagés.",
        question:
          "Serrez-vous prêt à vous impliquer dans la création ou l’entretien d’un jardin partagé ?",
        imageUrl: "Oui.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui",
            value: "rep1",
            image: "Oui.svg",
          },
          {
            label: "Non",
            value: "rep2",
            image: "Non.svg",
          },
          {
            label: "Oui, mais avec un suivi ponctuel",
            value: "rep3",
            image: "Oui-mais.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Création de jardins partagés.",
        question:
          "Quel type de jardin partagé convient le plus à vos attentes ?",
        imageUrl: "individuel.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des jardins individuels",
            value: "rep1",
            image: "individuel.svg",
          },
          {
            label: "Des jardins collectifs",
            value: "rep2",
            image: "collectif-&-au-sol.svg",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Création de jardins partagés.",
        question:
          "Quelle serait, selon vous, la forme de culture la plus sympa ?",
        imageUrl: "collectif-&-au-sol.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Culture au sol",
            value: "rep1",
            image: "collectif-&-au-sol.svg",
          },
          {
            label: "Carré potager",
            value: "rep2",
            image: "carrés-potagers.svg",
          },
          {
            label: "Bac surélevé",
            value: "rep3",
            image: "bacs-surélevés.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b"],
  },
];
