import React from "react";
import classes from "./CalendarCard.module.css";

const CalendarCard = ({
  emoji,
  title,
  index,
  selectedCard,
  setSelectedCard,
  setShow,
}) => {
  return (
    <div
      className={index === selectedCard ? classes.SelectedCard : classes.Card}
      onClick={() => {
        setSelectedCard(index);
        setShow(true);
      }}
    >
      <span className={classes.Emoji} role="img" aria-label="emoji">
        {emoji}
      </span>
      <p>
        <strong>{title}</strong>
      </p>
    </div>
  );
};

export default CalendarCard;
