import { db } from "../config/firebase";
import moment from "moment";

// Filter Validation
// export const testFilter = (filters, tags) => {
//   // filters array, tags array
//   let isValid = false;
//   let filtreBuffer = false;

//   tags.map((tag) => {
//     filtreBuffer = filtre.includes(tag);
//     isValid = isValid || filtreBuffer;
//   });

//   return isValid;
// };

// Upload answers to firebase
export const uploadAnswer = (
  locataireID,
  cardID,
  contentId,
  answers,
  dispatch
) => {
  const answer = {
    locataireID,
    cardID,
    answers,
    date: moment().format(),
  };

  db.collection("resultats")
    .doc(cardID + "+" + contentId + "+" + locataireID)
    .set(answer)
    .then((resp) => {
      dispatch({ type: "SET_HIDECARD" });
      dispatch({ type: "SET_SUBMITTED", cardID });
      console.log("Answer uploaded");
    })
    .catch((err) => console.log("Error while updating answer :", err));
};

// Load answers from firebase
export const loadAnswers = (locataireID, dispatch, setLoading) => {
  setLoading(true);
  db.collection("resultats")
    .where("locataireID", "==", locataireID)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        Object.keys(doc.data().answers).map((question) => {
          dispatch({
            type: "LOAD_ANSWERS",
            cardID: doc.data().cardID,
            contentId: doc.id.split("+")[1],
            value: doc.data().answers.value,
            comment: doc.data().answers.comment,
          });

          // if (doc.data().cardID[0] === "c") {
          //   if (question !== "comment") {
          //     dispatch({
          //       type: "LOAD_ANSWERS_C",
          //       cardID: doc.data().cardID,
          //       questionID: question,
          //       value: doc.data().answers[question].value,
          //       comment: doc.data().answers.comment,
          //       contentId: doc.id.split("+")[2]
          //     });
          //     // Set the image
          //     dispatch({ type: "UPDATE_IMAGE", cardID: doc.data().cardID });
          //   }
          // }
          // else if (doc.data().cardID[0] === "n") {
          //   dispatch({
          //     type: "LOAD_ANSWERS_NC",
          //     cardID: doc.data().cardID,
          //     comment: doc.data().answers.comment,
          //   });
          // }
        });

        setLoading(false);
      });
    })
    .catch((err) => console.log("Error getting document: ", err));
};

// Submit a reclamation
export const submitReclamation = (reclamation, locataireId) => {
  const newReclamation = {
    ...reclamation,
    locataireId: locataireId,
    dateCreation: moment().format("DD/MM/YYYY HH:mm"),
  };

  db.collection("reclamations")
    .add(newReclamation)
    .then((docRef) => {
      console.log("Submitted");
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

// Load users reclamation
export const loadUserReclamations = (setReclamation, locataireId) => {
  const reclamations = [];

  db.collection("reclamations")
    .where("locataireId", "==", locataireId)
    .orderBy("dateCreation", "desc")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        reclamations.push({
          reclamationId: doc.id,
          dateCreation: doc.data().dateCreation,
          locataireId: doc.data().locataireId,
          title: doc.data().title,
          description: doc.data().description,
          pictures: doc.data().pictures,
          tagOne: doc.data().tagOne,
          tagTwo: doc.data().tagTwo,
          answer: doc.data().answer,
        });
      });
    })
    .then(() => {
      setReclamation(reclamations);
    })
    .catch((err) => console.error(err));
};

// Load all reclamations
export const loadAllReclamations = (setReclamation, locataireId) => {
  const reclamations = [];

  db.collection("reclamations")
    .orderBy("dateCreation", "desc")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        reclamations.push({
          reclamationId: doc.id,
          dateCreation: doc.data().dateCreation,
          locataireId: doc.data().locataireId,
          title: doc.data().title,
          description: doc.data().description,
          pictures: doc.data().pictures,
          tagOne: doc.data().tagOne,
          tagTwo: doc.data().tagTwo,
          answer: doc.data().answer,
        });
      });
    })
    .then(() => {
      setReclamation(reclamations);
    })
    .catch((err) => console.error(err));
};

// Submit admin answer to reclamation
export const submitAdminAnswer = (
  reclamationId,
  adminAnswer,
  setReclamations,
  currentUserUid
) => {
  db.collection("reclamations")
    .doc(reclamationId)
    .update({
      answer: adminAnswer,
    })
    .then(() => {
      loadAllReclamations(setReclamations, currentUserUid);
    })
    .catch((err) => console.error(err));
};

// Delete reclamation
export const deleteReclamation = (
  reclamationId,
  setReclamations,
  currentUserUid
) => {
  db.collection("reclamations")
    .doc(reclamationId)
    .delete()
    .then(() => {
      loadAllReclamations(setReclamations, currentUserUid);
      console.log("Reclamation deleted successfully");
    })
    .catch((err) => console.error(err));
};

// Submit pdf
export const submitPdf = (pdfInfo, adminId) => {
  const newPdf = {
    ...pdfInfo,
    adminId: adminId,
    dateCreation: moment().format("DD/MM/YYYY HH:mm"),
  };

  db.collection("news")
    .add(newPdf)
    .then((docRef) => {
      console.log("");
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
};

// Load pdfs
export const loadPdfs = (setPdfs, setLoading) => {
  const pdfs = [];

  db.collection("news")
    .orderBy("dateCreation", "desc")
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        pdfs.push({
          id: doc.id,
          dateCreation: doc.data().dateCreation,
          title: doc.data().title,
          url: doc.data().url,
          type: doc.data().type,
        });
      });
    })
    .then(() => {
      setPdfs(pdfs);
      setLoading && setLoading(false);
    })
    .catch((err) => console.error(err));
};

// Delete PDF
export const deletePdf = (pdfId) => {
  db.collection("news")
    .doc(pdfId)
    .delete()
    .then(() => {
      console.log("");
    })
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
};
