import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled.div`
  a {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #fff;
    text-decoration: none;
    padding: 1rem 2rem;
    background: #f5a623;
    border-radius: 20px;
    display: flex;
    justify-content: center;
  }
`;

const NextButton = (props) => {
  return (
    <Button onClick={props.handleSubmit}>
      <Link to={props.href}>{props.children}</Link>
    </Button>
  );
};

export default NextButton;
