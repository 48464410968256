import React from "react";

export const CalendarData = [
  {
    id: 0,
    emoji: "🛣️",
    title: "Voirie et réseaux divers",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Travaux préparatoires",
      },
      {
        id: 1,
        emoji: "🏗️",
        title: "",
        date: "",
        duration: "",
        description: "Terrassement et démolition",
      },
      {
        id: 2,
        emoji: "🛣️",
        title: "",
        date: "",
        duration: "",
        description: "Voirie",
      },
      {
        id: 3,
        emoji: "💧",
        title: "",
        date: "",
        duration: "",
        description: "Assainissement",
      },
      {
        id: 4,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Tranchées et réseaux divers",
      },
      {
        id: 5,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Maçonnerie et génie civil",
      },
    ],
  },
  {
    id: 1,
    emoji: "🌿",
    title: "Végétalisation des extérieurs et mobiliers",
    date: "",
    duration: "",
    plan: "Carte_Phase-3.svg",
    substeps: [
      {
        id: 0,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Travaux préparatoires",
      },
      {
        id: 1,
        emoji: "🌿",
        title: "",
        date: "",
        duration: "",
        description: "Plantation",
      },
      {
        id: 2,
        emoji: "🔒",
        title: "",
        date: "",
        duration: "",
        description: "Serrurerie des jardins et signalétique",
      },
    ],
  },
  {
    id: 2,
    emoji: "👷",
    title: "Les halls",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Mise en place des gardes-corps des ramps",
      },
      {
        id: 1,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Rénovation des halls",
      },
    ],
  },
];
