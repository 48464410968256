import React, { useState } from "react";
import classes from "./CardList.module.css";
// Components
import Card from "./Card/Card";
import CardCreateReclamation from "./CardCreateReclamation/CardCreateReclamation";
import CardUserReclamation from "./CardUserReclamation/CardUserReclamation";
import CardAdminReclamation from "./CardAdminReclamation/CardAdminReclamation";
// Routing
import { withRouter } from "react-router";

const CardList = ({
  cards,
  dispatch,
  executeScroll,
  filters,
  claims,
  history,
}) => {
  const [reclamations, setReclamations] = useState();

  const openHandler = (index) => {
    // dispatch({ type: "SET_SHOWCARD", show: true });
    // dispatch({ type: "SET_SELECTEDCARD", selectedCard: index });
    history.push(`/travaux/${index}`);
  };

  const { réclamations, travaux } = filters;
  const réclamationsCheck =
    réclamations.value || (!réclamations.value && !travaux.value);
  const travauxCheck = travaux.value || (!travaux.value && !réclamations.value);

  return (
    <div className={classes.CardList}>
      <div className={classes.List}>
        {claims && cards && claims.groupe === ""
          ? cards.map((card, index) => {
              return (
                <Card
                  key={card.id}
                  cardName={card.id}
                  handleOpen={() => openHandler(card.id)}
                  titleStrong={card.titleStrong}
                  titleWeak={card.titleWeak}
                  imgSrc={card.thumbnail}
                  submitted={card.submitted}
                  type={card.type}
                  tags={card.tags}
                  executeScroll={executeScroll}
                  description={card.description.substring(0, 58) + "..."}
                />
              );
            })
          : claims &&
            cards &&
            cards
              .filter((card) => card.groupe.includes(claims.groupe))
              .map((card, index) => {
                return (
                  <Card
                    key={card.id}
                    cardName={card.id}
                    handleOpen={() => openHandler(card.id)}
                    titleStrong={card.titleStrong}
                    titleWeak={card.titleWeak}
                    imgSrc={card.thumbnail}
                    submitted={card.submitted}
                    type={card.type}
                    tags={card.tags}
                    executeScroll={executeScroll}
                    description={card.description.substring(0, 58) + "..."}
                  />
                );
              })}
        {claims && claims.admin ? (
          <CardAdminReclamation
            reclamations={reclamations}
            setReclamations={setReclamations}
          />
        ) : (
          <>
            <CardUserReclamation
              reclamations={reclamations}
              setReclamations={setReclamations}
            />
            <CardCreateReclamation setReclamations={setReclamations} />
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(CardList);
