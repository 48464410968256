import React, { createContext, useReducer } from "react";
import { LoginReducer } from "../reducers/LoginReducer";
import { codesLoc } from "../utils/LoginUtils";

export const LoginContext = createContext();

export const LoginContextProvider = (props) => {
  const [loginData, dispatch] = useReducer(LoginReducer, {
    refLoc: {
      value: "",
      validation: {
        required: true,
        minLength: 7,
        maxLength: 7,
        codes: codesLoc,
      },
      valid: true,
      actionType: "SET_REFLOC",
    },
    numTel: {
      value: "",
      validation: {
        required: true,
        minLength: 9,
        maxLength: 10,
        regex: /^\d+$/,
      },
      valid: false,
      actionType: "SET_NUMTEL",
    },
    CGU: {
      value: true,
      validation: {
        required: true,
      },
      valid: true,
      actionType: "SET_CGUCHECKED",
    },
    codeSMS: {
      value: "",
      validation: {
        required: true,
        minLength: 6,
        maxLength: 6,
      },
      valid: false,
      actionType: "SET_CODESMS",
    },
    loading: false,
    formIsValid: false,
    confResult: {},
    refShow: false,
    CGUShow: false,
    telShow: false,
    SMSShow: false,
    isNewUser: true,
  });

  return (
    <LoginContext.Provider value={{ loginData, dispatch }}>
      {props.children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;
