import app, { db } from "../config/firebase";
import moment from "moment";
import axios from "axios";

axios.defaults.baseURL =
  "https://europe-west1-projet-homy.cloudfunctions.net/api";

// Codes
export const codesLoc = [
  "G14qP10",
  "u72597V",
  "P800r8J",
  "9eb3B91",
  "D20680e",
  "0Pd0J27",
  "5c64t7H",
  "T971pm9",
  "6429EGi",
  "a2L4597",
  "h598C53",
  "9o01S9f",
  "X8n3q37",
  "92qV32j",
  "02um82F",
  "nu5538M",
  "H95sA20",
  "f2r35Q4",
  "qo929D4",
  "Qg3683U",
  "68e77Fr",
  "ZW80n43",
  "2040gJz",
  "6R15vv1",
  "008cPu8",
  "3gk91E8",
  "9Dg2y14",
  "X72kw51",
  "1ozM256",
  "6V345bB",
  "2g45Qj5",
  "A8i039Q",
  "84sq2C7",
  "849Dw9b",
  "6kz2L12",
  "522CMu2",
  "73q66Ax",
  "S2p9W08",
  "7Y16T4b",
  "6r8r63G",
  "p528f4O",
  "2898b4W",
  "03Yr0a0",
  "7o420Md",
  "I504w5P",
  "7KS213r",
  "8bh0M68",
  "78g8C0W",
  "8s67y6D",
  "mmW4345",
  "t4l42X0",
  "m7883Jw",
  "1r1BS94",
  "54t5rI8",
  "58yhK84",
  "hH412K9",
  "92AT5i3",
  "6kLN009",
  "8O5a83N",
  "5Z946Zq",
  "1a3Yg12",
  "52C5x7T",
  "843x9uG",
  "U538ad3",
  "L434l4w",
  "k862B1F",
  "F3c904F",
  "Ic6376e",
  "39LD94j",
  "712MX0l",
  "68K1Z7c",
  "65U7u6I",
  "71YQi70",
  "1LoD384",
  "663Vbv6",
  "j0994RY",
  "4d5M6o4",
  "25d5W7T",
  "48M2gK4",
  "6611kuE",
  "ti486D7",
  "4Dx3l11",
  "31J8w4k",
  "2Oo69o4",
  "u1p2X60",
  "1yT85x7",
  "yW88G86",
  "b087Q65",
  "nPP4644",
  "6Tw06B8",
  "2hDd540",
  "5D0Pc92",
  "v19HO98",
  "718Yn4S",
  "6J5b1Y1",
  "0H4v0o1",
  "73qX4s0",
  "S14Gg68",
  "0rRD080",
  "9Aq5h43",
  "7xB7b19",
  "6464qkP",
  "Jb98067",
  "zU728C3",
  "a1w648U",
  "e8E636t",
  "4i6Dh47",
  "112PEi1",
  "8WI7n09",
  "FX700u1",
  "5h9i9A5",
  "4Z18cw2",
  "f9K436p",
  "0gX9F31",
  "531BA4j",
  "1Mcu486",
  "710UR4k",
  "683HH9o",
  "224gD1d",
  "d2N0O79",
  "82E87Oj",
  "5i259AL",
  "z9I560Z",
  "W7h362f",
  "c7Di537",
  "8Ra8l38",
  "q2E8X32",
  "57jXn98",
  "md512H3",
  "7h21An4",
  "M8xu166",
  "37kK3j9",
  "173G8Gc",
  "RL2x263",
  "51Qv2g6",
  "S50h430",
  "5e658MK",
  "18N1Ev1",
  "Flf9437",
  "0DT7x79",
  "v6674Us",
  "163pN6F",
  "U4c0d75",
  "19r7Tp4",
  "4v4t74H",
  "al9090G",
  "P896rp7",
  "0d8W3P2",
  "qrG2440",
  "Zcv5636",
  "88f5M97",
  "522Vs8a",
  "688F3Iv",
  "A8jj857",
  "2Uv144r",
  "365yUP9",
  "5M31O6d",
  "kbJ9126",
  "89hD6v2",
  "aU848x8",
  "5bL309A",
  "z729yE2",
  "O58sW45",
  "sJ4R998",
  "v7Z3A39",
  "h99Sl75",
  "i8I06e3",
  "y0267J5",
  "LJ851p7",
  "Sm8S713",
  "5iP5x32",
  "04qV63j",
  "w7O710I",
  "l60J4Y6",
  "X9R3d70",
  "12zXr04",
  "m6u07L8",
  "p93qL38",
  "5m88D4w",
  "LGr9006",
  "tT7M352",
  "261d1rP",
  "8922I6r",
  "I8k8T33",
  "10ZQ76n",
  "1i4D39X",
  "891N3aZ",
  "442Z85y",
  "C0U42r0",
  "M91l7g2",
  "239BJp1",
  "6x3O0L5",
  "3JV199s",
  "1r901SL",
  "1xKY171",
  "Yv5i480",
  "Yt780e9",
  "t38XM31",
  "I55rp96",
  "Rpr3143",
  "2GH0j60",
  "777Bm3Q",
  "z90gL39",
  "042Je2p",
  "Y167op8",
  "Qx71v59",
  "Ac0n103",
  "7760uhG",
  "0X34hp6",
  "kT36Z40",
  "wA5072B",
  "9Bs306U",
  "W2d13S6",
  "08J73oT",
  "Za478T5",
  "1i98o6S",
  "h13N568",
  "1y8O191",
  "4I9b976",
  "4147MVc",
  "Y79do29",
  "4q5M81H",
  "87s0X7Y",
  "b03L48W",
  "8Jd99w8",
  "5O8H8b3",
  "17J75am",
  "OS10e59",
  "9a6S57v",
  "1e3L41U",
  "6E5zX00",
  "6UC76j5",
  "jq4383E",
  "364LpB4",
  "6Id77y9",
  "1H94x87",
  "74E5K2m",
];

// Checks for validity
export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.regex) {
    isValid = rules.regex.test(value) && isValid;
  }

  if (rules.codes) {
    isValid = rules.codes.includes(value) && isValid;
  }

  return isValid;
};

// custom token login
export const customLogin = (refLoc, dispatch) => {
  // console.log("Axios function");
  axios
    .post("/login-garges", { code: refLoc.value })
    .then((res) => {
      // console.log(res);
      app
        .auth()
        .signInWithCustomToken(res.data.token)
        .then((res) => {
          // console.log("Auth success: ", res.data);
          dispatch({ type: "SET_LOADING", loading: false });
        })
        .catch((err) => {
          console.log("auth error: ", err);
          dispatch({ type: "SET_LOADING", loading: false });
        });
    })
    .catch((err) => {
      console.log("axios error: ", err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Anon Login
export const anonLogin = (dispatch) => {
  app
    .auth()
    .signInAnonymously()
    .then((resp) => {
      console.log("Login successful");
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Add new locataire
export const addNewLoc = (refLoc, locataireID) => {
  const newLoc = {
    refLoc,
    locataireID,
    dateCreation: moment().format(),
  };
  if (refLoc !== "") {
    db.collection("locataires")
      .doc(refLoc)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  } else {
    db.collection("locataires")
      .doc(`anon-${locataireID}`)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  }
};
